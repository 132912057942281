/******************* Django i18n *******************/

// This is just a check to see whether Django jsi18n has been loaded, to give a
// clear error message rather than (or at least before) a bunch of confusing
// errors later on.
if (!window.django) {
  console.error("window.django not defined - ensure Django's jsi18n is loaded before this script")
}

// Now to set up everything else

// Best not to import all of bootstrap and just uncomment the parts we're actually using:
// https://getbootstrap.com/docs/5.0/customize/optimize/#lean-javascript

// import 'bootstrap/js/dist/alert'
import 'bootstrap/js/dist/button'
// import 'bootstrap/js/dist/carousel'
// import 'bootstrap/js/dist/base-component'
import 'bootstrap/js/dist/dropdown'
// import 'bootstrap/js/dist/collapse'
// import 'bootstrap/js/dist/modal'
//import 'bootstrap/js/dist/popover'
import 'bootstrap/js/dist/offcanvas'
import 'bootstrap/js/dist/scrollspy'
import 'bootstrap/js/dist/tab'
// import 'bootstrap/js/dist/toast'
// import 'bootstrap/js/dist/tooltip'

import './navigation' 
//import './toggle-layout'
//import './api-endpoints'
//import './modal-dashboard-top-offenders'
//import './dashboard'
