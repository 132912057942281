const updateClass = () => {
    const body = document.body;
    const sidebarPanel = document.querySelector('.sidebar-panel');
    const btnMenu = document.querySelector('.btn-menu');
    const offcanvasBackdrop = document.querySelector('.offcanvas-backdrop');

    if (window.innerWidth <= 1080) {
        // Mobile
        sidebarPanel.classList.add('offcanvas', 'offcanvas-start');
        sidebarPanel.setAttribute('aria-labelledby', 'sidebarPanelLabel');
        sidebarPanel.setAttribute('tabindex', '-1');
    } else {
        // Desktop
        body.style='';
        sidebarPanel.classList.remove('offcanvas', 'offcanvas-start', 'show');
        sidebarPanel.removeAttribute('aria-labelledby');
        sidebarPanel.removeAttribute('tabindex');
        sidebarPanel.removeAttribute('aria-modal');
        sidebarPanel.removeAttribute('role');

        // Check if the element exists before trying to remove it
        // Not the best approach to do this but it's what I can think off...
        // The Idea is to remove the offcanvas when it stays open on mobile and closes automatically on desktop
        if (offcanvasBackdrop) {
            // Very bad and not sure how to reset the value
            // I just trigger the click event to reset the offcanvas value
            offcanvasBackdrop.remove();
            btnMenu.click();
        } 
    }
};

updateClass();

window.addEventListener('resize', updateClass);
